import alerts from '@/alerts/alert'
import AuthenticationService from '@/services/AuthenticationService'
import store from '@/store'

const product = {
  namespaced: true,
  state: {
    modal: false,
    form: {
      sourceFrom: 'Solusi One',
      productCode: '',
      productName: '',
      productDescription: '',
      productPrice: '0',
      contactCompanyId: '1',
      contactType: 'PERSONAL',
      businessCode: '',
      productLink: '',
      contactName: '',
      email: '',
      mobilePhoneNumber: '',
      notes: ''
    }
  },
  getters: {

  },
  mutations: {
    SET_MODAL (state, bolean) {
      state.modal = bolean
    },
    SET_FORM (state, productName) {
      state.form.productName = productName
      state.form.productCode = productName
      state.form.businessCode = productName
      state.form.productDescription = productName
    }
  },
  actions: {
    goModalForm ({ commit, state }, productName) {
      if (store.getters['auth/user']) {
        commit('SET_FORM', productName)
        state.form.contactName = store.getters['auth/user'].name
        state.form.email = store.getters['auth/user'].email
        state.form.mobilePhoneNumber = store.getters['auth/user'].phone
        commit('SET_MODAL', true)
      } else {
        commit('SET_MODAL', true)
        commit('SET_FORM', productName)
      }
    },
    async tryProduct ({ commit, state }) {
      if (state.form.contactName || state.form.email || state.form.mobilePhoneNumber || state.form.notes) {
        store.dispatch('loading/setLoadingDefault', true)
        let endpoint = 'cmrs-submission'
        if (store.getters['auth/user']) {
          endpoint = 'account/cmrs-submission'
        }
        return await AuthenticationService.post(endpoint, state.form).then(({ data }) => {
          store.dispatch('loading/setLoadingDefault', false)
          if (data.success) {
            commit('SET_MODAL', false)
            alerts.sweetalertToast('success', 'Success')
          } else {
            alerts.sweetalertToast('error', 'Failed')
          }
        }).catch(error => {
          store.dispatch('loading/setLoadingDefault', false)
          alerts.sweetalertToast('error', error.response.data.message)
        })
      }
    }
  }
}

export default product
